<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="single_sms">
        <b-row>
          <b-col md="6">
            <b-card-text>
              <span>Message To </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Message To"
                rules="required"
              >
                <b-form-tags
                  v-model="msg_to"
                  name="msg_to[]"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Message To"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-text>
              <span>Message Body </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Message text"
                rules="required"
              >
                <b-form-textarea rows="5"
                  v-model="msg_text"
                  name="msg_text"
                  placeholder="Message Text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormTextarea,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  //BFormRadioGroup,
  BFormTags
} from "bootstrap-vue";
// import { required, integer } from "@validations";
import apiCall from "@/libs/axios";

export default {
  name: "SingleSms",
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    //BFormRadioGroup,
    BFormTags
  },
  data() {
    return {
      msg_to: [],
      msg_type:1,
      msg_options: [
        { text: "En", value: 1 },
        { text: "Bn", value: 0 },
      ],
      msg_text: "",
    };
  },
  created() {
    //this.getAll();
  },
  methods: {
    async validationForm() {
      await this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let data = new FormData(this.$refs.single_sms);
           apiCall.post("/single/sms/store", data)
            .then((response) => {
              if (response.data.status === "success") {
                this.$toaster.success(response.data.message);
                this.$refs.single_sms.reset();
                this.$refs.simpleRules.reset();
              } else {
                this.$toaster.error(response.data.message);
              }
            })
            .catch((error) => {
              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
        }
      });
    },
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>